/* function sizeOfThings(){
	if($('#mainContent').css('height')!= undefined){
		var windowHeight = Number($('#mainContent').css('height').replace("px",""))//window.innerHeight;

		if($('.content-wrapper').css('height') != undefined){
			htmlHeight =Number($('.content-wrapper').css('height').replace("px","")) + 117;
		}

		if(windowHeight > (htmlHeight)){
			$('.content-wrapper').css('height', ($(window).height()-153)+'px');
		}
	}
  };

window.onload = function () {
    sizeOfThings();
}

window.addEventListener('resize', function(){
    sizeOfThings();
}) */